@use '../../globals';

#report-generator {
  height: globals.$header-with-submenu-height;
  display: grid;
  grid-template-columns: 1fr;

  .table-container {
    grid-row: 1;

    table {
      height: 100%;
    }
  }

  .footer {
    grid-row: 2;
    box-sizing: border-box;
    border-top: 2px solid rgba(37, 53, 77, 0.2);
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 0 20px #00000012;
    height: initial;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;

    .row {
      display: flex;
      flex-direction: row;

      .row-description {
        width: 10rem;
        padding: 10px;
        flex: 0 0 auto;
      }

      .report-items {
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;

        .report-item {
          display: flex;
          width: 250px;
          padding: 10px;

          .check-box {
            height: 20px;
            width: 20px;
            padding-right: 10px;
          }
        }
      }

      .date-range-and-confirm {
        flex: 1;
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        column-gap: 0.6rem;

        .data-range-container {
          display: flex;
          align-items: center;

          span {
            margin: 0 .8em;
          }
        }

        .allowed-events-km-slider {
          display: flex;
          align-items: center;
          column-gap: 0.8rem;

          p {
            margin: 0;
          }
        }
      }
    }
  }

  &.vehicle-report-generator {
    grid-template-rows: 1fr 18rem;

    .table-container table {
      height: 100%;
      max-height: calc(100vh - 150px - 18rem);
    }

    &.basic {
      grid-template-rows: 1fr 8rem;

      .table-container table {
        max-height: calc(100vh - 150px - 8rem);
      }
    }

    @media (max-width: 1400px) {
      grid-template-rows: 1fr 21rem;

      .footer {
        padding-inline: 1rem;

        .row-description {
          display: none;
        }
      }

      .table-container table {
        max-height: calc(100vh - 150px - 21rem);
      }
    }
  }

  &.driver-report-generator {
    grid-template-rows: 1fr 7rem;

    .table-container table {
      height: 100%;
      max-height: calc(100vh - 150px - 7rem);
    }
  }

  &.poi-report-generator {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "vehicles pois" "footer footer";

    > div {
      &:not(:first-child) {
        border-left: 1px solid #D3D6DB;
      }

      header {
        text-align: center;
        font-weight: 600;
        line-height: 40px;
        border: solid #D3D6DB;
        border-width: 1px 0;
      }

      table {
        max-height: calc(100vh - 110px - 40px - 180px);
      }
    }

    > .vehicles {
      grid-area: vehicles;
    }

    > .pois {
      grid-area: pois;
    }

    > footer {
      border-top: 1px solid #D3D6DB;
      grid-area: footer;
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "label1 types types" "label2 dates submit";
      align-items: center;

      > * {
        padding: 0 2em;
      }

      .label {
        grid-area: label2;

        &:nth-child(1) {
          grid-area: label1;
        }
      }

      .types {
        grid-area: types;
        display: grid;
        grid-gap: 2em;
        grid-template-columns: repeat(2, min-content);

        .field {
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          input {
            margin-right: 1em;
          }
        }
      }

      .dates {
        grid-area: dates;
        display: grid;
        grid-template-columns: repeat(3, fit-content(100%));
        grid-gap: 1em;
        align-items: center;
      }

      .submit {
        grid-area: submit;
        justify-self: flex-end;
      }
    }

    #poi-table {
      img {
        vertical-align: middle;
        margin-right: .5em;
      }

      small {
        font-size: .8em;
        font-style: italic;
        color: #999999;
      }
    }
  }
}
