@import '../../globals.scss';

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: $input-bg-color;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;

  &:hover {
    opacity: 1;
  }

  $width: 25px;
  $height: 25px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: $width;
    height: $height;
    background: $blue;
    border-radius: $border-radius;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: $width;
    height: $height;
    background: $blue;
    border-radius: $border-radius;
    cursor: pointer;
  }
}

