@import '../../globals';

.device-list-item {
  width: 100%;
  background-color: $navy;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;

  &:hover {
    background-color: #2e5171;
  }

  .device-list-item-content {
    padding: 10px 20px 5px 0;
    margin: 0 0 0 15px;
    position: relative;

    &.selected {
      border-radius: $border-radius 0 0 $border-radius;
      background-color: #ffffff;

      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background: url("../../graphics/outside-corner-top-right.png") center no-repeat;
        right: 0;
      }

      &:before {
        top: -10px;
      }

      &:after {
        bottom: -10px;
        transform: rotate(-90deg);
      }
    }

    .device-list-item-row {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: row;
      height: fit-content;
      align-items: center;
      margin-left: 5px;

      .device-status-image {
        width: 20px;
        height: 20px;
      }

      .device-ignition-image {
        width: 14px;
        height: auto;
      }

      .device-name {
        flex: 1;
        height: fit-content;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        color: #ffffff;

        &.selected {
          font-weight: 500;
          color: $navy;
        }
      }
    }

    .localization, .reading-time, .temperatures, .vehicle-driver, .puesc-integration-row {
      margin: 0 0 5px 35px;
      font-size: 12px;

      &.selected {
        font-weight: 500;
        color: $navy;
      }

    }

    .localization {
      color: rgba(255, 255, 255, 0.7);

      &.selected {
        font-weight: 600;
      }
    }

    .reading-time {
      color: rgba(255, 255, 255, 0.4);
    }

    .temperatures {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 5px;

      .temperature-item {
        text-align: center;
        background-color: rgba(255, 255, 255, .1);
        border-radius: $border-radius;
        padding: 2px;
        color: rgba(255, 255, 255, 0.7);

        &:first-child {
          margin-left: 0;
        }
      }

      &.selected .temperature-item {
        color: #fff;
        background-color: $navy;
      }
    }

    .vehicle-driver {
      color: rgba(255, 255, 255, 0.4);

      img {
        margin-right: 5px;
        width: 10px;
        height: 10px;
      }
    }

    .puesc-integration-row {
      display: flex;
      align-items: center;
      column-gap: 5px;

      .puesc-integration-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        border-radius: 20px;
        padding: 2px;
        color: #fff;
        font-weight: 600;
      }
    }

    .license-warning {
      display: flex;
      align-items: center;
      column-gap: .5rem;
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff;
      border-radius: 10px;
      padding: .2rem .4rem;

      svg {
        width: 1.4rem;
      }

      p {
        margin: 0;
        font-size: .75rem;
      }

      &.selected {
        color: $navy;
        background-color: transparent;
      }
    }
  }
}

