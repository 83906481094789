@import '../../globals';

.map-chat-content {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  background-color: #ffffff;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 10px;

  .chat-bg {
    $size: 120px;
    width: $size;
    height: $size;
    position: absolute;
    top: calc(50% - (#{$size} / 2));
    left: calc(50% - (#{$size} / 2));
    stroke: #0d101a19;
  }

  .user-list-container {
    flex: .4;
    border-radius: 10px 0 0 10px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 0;
    }

    .user-list {
      overflow-y: scroll;
    }
  }

  .messages-list-container {
    flex: .6;
    display: flex;
    flex-direction: column;
    border-radius: 0 10px 10px 0;
    background-color: #ffffff;
    overflow: hidden;

    ::-webkit-scrollbar {
      width: 0;
    }

    .messages-list {
      flex: 1;
      overflow-x: hidden;
      overflow-y: scroll;

      .no-messages-info-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .no-messages-info {
          color: $navy;
        }
      }
    }

    .window-control {
      height: 40px;
      display: flex;
      justify-content: flex-end;

      .exit-button {
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .exit-button-image {
          width: 20px;
          height: 20px;
        }
      }
    }

    .messages-controls {
      min-height: 60px;
      height: fit-content;
      max-height: 150px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon-container {
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .input-file {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          z-index: -1;
        }

        .icon {
          width: 20px;
          height: 20px;
          cursor: pointer;

          &.opacity {
            opacity: .2;
          }

          &.send:not(.opacity):hover {
            transform: rotateX(50deg);
          }
        }
      }

      .new-message-input {
        flex: 1;
        background: $input-bg-color 0 0 no-repeat padding-box;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        min-height: 40px;
        height: fit-content;
        max-height: 130px;
        padding: 3px;
        margin: 3px;

        &.hidden {
          display: none;
        }
      }

      .image-container {
        flex: 1;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        min-height: 40px;
        height: fit-content;
        max-height: 130px;
        padding: 3px;
        margin: 3px;
        display: none;
        justify-content: center;
        position: relative;

        .remove-button {
          position: absolute;
          background-color: white;
          display: flex;
          justify-content: center;
          border-radius: 30px;
          align-items: center;
          width: 30px;
          height: 30px;
          top: 0;
          right: 0;

          &:hover {
            background-color: #979a9d;
            border-radius: 30px;
          }

          .icon {
            width: 20px;
            height: 20px;
          }
        }

        &.visible {
          display: flex;
        }

        .image {
          max-width: available;
          max-height: 120px;
          display: block;
        }

      }
    }
  }
}
