@import '../../globals';

#external-integrations {
  .columns {
    display: flex;
    border-top: 2px solid $border-color;
    height: $header-with-submenu-height;
    overflow-y: auto;

    .column {
      flex: 1;

      &:first-child .column-content {
        border-right: 1px solid $border-color;
      }

      .column-content {
        display: flex;
        flex-direction: column;

        #puesc-vehicles, #transport-exchanges {
          padding: 20px;
          display: flex;
          flex-direction: column;
          row-gap: 1.5rem;

          .header {
            font-weight: 600;
            color: $navy;
            font-size: 1.1rem;
          }

          .description {
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: .5rem;
            min-height: 4rem;

            & > div {
              display: grid;
              grid-template-columns: auto 1fr;
              column-gap: 1rem;

              p {
                margin: 0;
                color: $navy;
                opacity: .4;
              }

              svg {
                width: 1.5rem;
                height: 1.5rem;
                color: $blue;
              }
            }

            .button {
              margin-right: 0;
              display: flex;
              column-gap: .5rem;

              svg {
                width: 1rem;
                height: 1rem;
              }
            }
          }

          .table-container {
            table {
              max-height: 600px !important;
            }
          }
        }

        #puesc-vehicles {
          .puesc-active-info {
            display: flex;
            align-items: center;
            column-gap: 1rem;

            span {
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 1.5rem;
              }
            }
          }
        }

        .table-container {
          table {
            width: 100%;
            border: 1px solid $navy;
            border-radius: $border-radius;

            td {
              border: none;
            }
          }

          .no-data {
            text-align: center;
            font-size: 1.1rem;
            margin: 0;
          }
        }

        .logo-container {
          display: flex;
          align-items: center;
          column-gap: 1rem;

          img {
            width: 4rem;
          }
        }
      }
    }
  }
}

#show-transport-exchange {
  .group {
    dl > dd {
      text-align: right;
    }

    .reset-password-button-container {
      display: flex;
      justify-content: flex-end;
    }

    &.vehicles-container {
      .title {
        margin-bottom: 1rem;
      }

      .button.basic {
        display: flex;
        align-items: center;
        column-gap: .5rem;
      }
    }
  }

  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: 1rem;
  }
}

#add-transport-exchanges {
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      min-width: unset;
      width: 2rem;
      height: 2rem;
    }
  }
}

#transport-exchanges-summary-modal {
  width: 40rem;

  main {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    overflow-y: auto;
    max-height: 500px;

    svg {
      width: 1.5rem;
    }

    img {
      width: 4rem;
    }

    .created-exchanges-container, .creation-errors-container {
      & > span {
        display: flex;
        align-items: center;
        column-gap: .5rem;

        p {
          margin: 0;
          font-size: 1.1rem;
        }
      }

      ul {
        list-style-type: none;

        li:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }

    .created-exchanges-container {
      & > span > svg {
        stroke: $green;
      }

      .info-container {
        display: flex;
        align-items: center;
        column-gap: .5rem;
        margin-block: 1rem;
        font-size: .9rem;

        svg {
          color: $blue;
        }
      }

      ul {
        li:not(:last-child) {
          margin-bottom: 3rem;
        }
      }
    }

    .creation-errors-container {
      svg {
        stroke: $red;
      }

      ul {
        li {
          display: flex;
          align-items: center;
          column-gap: .5rem;
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;
  }
}

#reset-password-transport-exchange-modal {
  min-width: 20rem;

  footer.summary {
    display: flex;
    justify-content: center;
  }
}

#edit-vehicles-transport-exchange {
  .vehicles-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;

    .field {
      grid-template-columns: auto 1fr;

      img {
        grid-column: 1 !important;
      }

      .input-label {
        grid-column: 2 !important;
      }
    }
  }
}
