@import '../../globals';

.panel-right {
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, .3);

  min-width: 280px;
  width: 100%;
  max-width: 560px;
  &.wide {
    max-width: 768px;
  }
  &.wide-2x {
    max-width: 1536px;
  }

  pointer-events: none;
  opacity: 0;
  transform: translateX(100%);
  transition-duration: .4s;
  transition-property: opacity, transform;


  display: flex;
  flex-direction: column;

  .button:last-child {
    margin-right: 0 !important;
  }

  > .header {
    flex: 0 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(37, 53, 77, 0.3);
    padding: 0 1em;

    .close {
      flex: 0 0;
      padding: 1rem;
      font-size: 2em;
      color: $blue;
      cursor: pointer;
      margin-left: -1rem;
    }

    .title {
      text-align: center;
      flex: 1 1 auto;
      color: $blue;
      font-weight: 700;
      font-size: 24px;
    }

    .button.icon {
      min-width: initial !important;
      padding: .5em;
      svg {
        vertical-align: middle;
        width: 24px;
        height: 24px;
        path {
          stroke-width: 1.25;
        }
      }
    }
  }

  > .body {
    flex: 1 1 auto;
    overflow-y: scroll;
    padding: 1em;
    box-shadow: inset 0 -30px 30px -30px rgba(0, 0, 0, 0.1);
  }

  > .footer {
    flex: 0 0 auto;
    height: 50px;
    background-color: #fff;
    padding: 0 1em;
  }

  &.visible {
    pointer-events: auto;
    opacity: 1;
    transform: translateX(0);
  }
}

.panel-right-module {

  .coat {
    z-index: 1000;
    position: absolute;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
    opacity: 0;
    pointer-events: none;
    transition: .4s opacity;
  }




  &.visible {
    .coat {
      opacity: 1;
      pointer-events: auto;
    }
    .panel-right {
      pointer-events: auto;
      opacity: 1;
      transform: translateX(0);
      z-index: 1001;
    }
  }
}

.panel-right-form, .panel-right-entity-details {
  .body {
    padding-top: 0;
  }
}

.panel-right-form, form {
  .errors {
    color: #e74c3c;
  }
  .field {
    margin: 1em 0;
    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-gap: 1em;
    align-items: center;

    .react-datepicker-wrapper {
      width: 100%;
      input {
        width: 100%;
        box-sizing: border-box;
      }
    }

    select, input {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      border: 1px solid $border-color;
      background-color: $input-bg-color;
      border-radius: $border-radius;

      margin: 0;
      padding: .5em 1.5em;
      font-weight: 600;
      font-size: inherit;

      text-align: right;

      &:invalid {
        border-color: #e74c3c;
        box-shadow: 0 0 4px #e74c3c;
      }
      &::placeholder {
        font-weight: 400;
      }
    }
    select {
      background: {
        image: url("../../graphics/arrow_down.svg");
        position: right 6px center;
        repeat: no-repeat;
      };
      direction: rtl;
      option {
        direction: ltr;
        &:disabled {
          color: #666666;
        }
      }
    }

    &.error {
      select, input, textarea {
        border-color: #e74c3c;
        box-shadow: 0 0 4px #e74c3c;
      }
    }
    &.buttons {
      display: block;
      text-align: right;
    }
    &.required {
      label {
        font-weight: 700;
        &:after {
          content: ' *';
        }
      }
    }
  }
}

.panel-right-entity-details {
  dl {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: .5em 1em;
    width: 100%;

    dt {
      white-space: nowrap;
    }

    dd {
      font-weight: 600;
    }
  }

  .group {
    position: relative;
    > .title {
      font-weight: 700;
      width: 100%;

      > .icon {
        cursor: pointer;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    padding: 1em 0;

    &.hint {
      display: grid;
      grid-template-columns: 30px 1fr;
      grid-gap: 10px;
      .icon {
        margin-right: 1em;
      }
      .text {
        padding: .2em;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: -1em;
      bottom: 0;
      width: calc(100% + 2em);
      border-bottom: 1px solid rgba(37, 53, 77, 0.11);
    }

    &:last-child:after {
      display: none;
    }

    ul.group-list {
      list-style: none;
      padding: 0;
    }
  }

  .inline-info-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
