@import '../../globals';

#login-view {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr min-content;
  grid-template-areas: "greeter window" "version version";
  align-items: center;


  .greeter {
    grid-area: greeter;
  }

  .window {
    grid-area: window;
  }

  .version {
    grid-area: version;
  }

  > section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  background: #2c4160 center / cover;

  &.business {
    background-image: url("../../graphics/backgrounds/login_back_busin.jpg");
  }

  &.individual {
    background-image: url("../../graphics/backgrounds/login_back_ind.jpg");
  }


  * {
    box-sizing: border-box;
  }

  .greeter > * {
    color: #fff;
    max-width: 340px;

    .logo {
      line-height: 1;

      img {
        width: 100%;
        vertical-align: middle;
      }
    }

    .description {
      margin: 1em 0;
    }

    hr {
      margin: 1.5em 0;
      border: 0;
      height: 1px;
      opacity: .4;
      background-color: #fff;
    }

    .links {
      & > a {
        opacity: .4;
        text-decoration: none;
        color: #fff;

        &:hover {
          opacity: 1;
        }
      }
    }

    .links-section {
      display: flex;
      flex-direction: column;
      
      gap: 1rem;
    }
  }

  form {
    &.individual {
      background-color: #355a98;
    }

    &.business {
      background-color: #ffffff;
    }

    footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
    }
  }

  .form form {
    width: 320px;
    box-sizing: border-box;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, .3);
    border-radius: $border-radius;
    background-color: #fff;
    overflow: hidden;
    margin: 1em;

    &.individual {
      background-color: #355a98;
    }

    &.business {
      background-color: #ffffff;
    }

    //position: relative;

    header, main, footer {
      padding: 1em;
    }

    .tabs {
      height: 40px;

      :focus {
        outline: 0;
      }

      .tab {
        width: 50%;
        height: 100%;
        font-size: 1em;
        border: none;
        cursor: pointer;

        &:hover {
          opacity: .9;
        }

        &.business {
          background-color: #24344e;
          color: #acb2bb;
          border-radius: $border-radius 0 0 0;

          &.selected {
            background-color: #ffffff;
            color: $navy;
            font-weight: 600;
          }
        }

        &.individual {
          background-color: #24344e;
          color: #acb2bb;
          border-radius: 0 $border-radius 0 0;

          &.selected {
            background-color: #355a98;
            color: #ffffff;
            font-weight: 600;
          }
        }
      }
    }

    header {
      font-weight: 700;
      text-align: center;
      border-bottom: 1px solid rgba(37, 53, 77, 0.2);
      font-size: 1.2em;
    }

    main {
      height: 190px;
      position: relative;

      .loader {
        position: absolute;
        top: -8%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    main input {
      width: 100%;
      padding: .5em;
      border-radius: 3px;
      border: 1px solid $border-color;
      background-color: #EFEFEF;
      margin: 1em 0;
      font-size: 1em;

      &.individual {
        color: #d3d9e5;
        background-color: #335288;
        border: none;
      }
    }

    main input.individual::placeholder {
      color: #d3d9e5;
    }

    footer {
      &.individual {
        background-color: #31528a;
      }

      &.business {
        background-color: #efefef;
      }
    }

    footer {
      border-top: 1px solid rgba(37, 53, 77, 0.2);
      text-align: center;

      .button {
        font-size: 1.2em;
      }
    }
  }

  #forgot-password-container, #change-password-container, #consents-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .coat {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, .4);
      pointer-events: none;
    }

    form {
      width: 460px;
      position: relative;

      main {
        padding: 1em 80px;
      }
    }
  }

  #change-password-container {
    form main {
      height: 250px;
    }
  }

  #consents-container {
    form main {
      height: fit-content;
    }

    .consent {
      display: flex;
      align-items: center;
      margin: 1em 0;
    }

    input[type="checkbox"] {
      width: min-content;
      align-self: start;
      margin: 0 1em 0 0;
    }
  }

  .version, .copyright, .forgot-password {
    color: #fff;
    opacity: .4;
    padding-bottom: .7em;
  }

  .forgot-password:hover {
    opacity: 1;
  }

  .version {
    padding: 1em;
  }
}
