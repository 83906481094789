@import '../../globals';

.counter-input {
  width: 10rem;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  align-items: center;
  column-gap: .5rem;
  height: 2rem;

  button {
    height: 2rem;
    width: 2rem !important;
    min-width: 2rem !important;
    background-color: $input-bg-color;
    border: 1px solid $border-color;

    &:not(button:disabled) {
      cursor: pointer;

      &:hover {
        opacity: .9;
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .7;
    }


    svg {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    text-align: center;
    border: 1px solid $border-color;
    background-color: $input-bg-color;
    border-radius: $border-radius;
    font-weight: 600;
    height: 2rem !important;
    width: 2rem;
    justify-self: center;
    padding: 0;
    margin: 0;
  }

  button {
    border: 1px solid $border-color;
    border-radius: $border-radius;
  }

  .delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 2rem;
    height: 2rem;

    &:not(.disabled):hover {
      path {
        opacity: 1;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }

    svg {
      width: 1.5rem !important;

      path {
        stroke: black;
        opacity: .4;
        stroke-width: 1.5px;
      }
    }
  }
}
