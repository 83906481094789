@use '../../globals';

#order-route-creator {
  height: globals.$header-with-submenu-height;

  .creator-and-map {
    height: 100%;
    display: grid;
    grid-template-columns: 500px 1fr;

    .route-creator {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      border-top: 2px solid globals.$border-color;

      .header {
        width: 100%;
        text-align: center;
        font-size: 24px;
        color: globals.$blue;
        box-sizing: border-box;
        padding: 10px;
      }

      .drag-and-drop-list {
        display: flex;
        flex: 1;
        max-height: 600px;
      }

      .footer {
        height: 60px;
        background-color: globals.$border-color;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    #order-route-map {
      width: 100%;
      height: 100%;
    }
  }
}
