@import '../../globals';

#vehicles {
  #vehicle-list {
    table {
      max-height: $header-with-submenu-height;

      th, td {
        display: flex;
        align-items: center;
      }

      .additional-info {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        p {
          margin: 0;
        }
      }

      .license-end-info {
        display: flex;
        align-items: center;
        column-gap: .5rem;

        svg {
          width: 1.4rem;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

#vehicle-add, #vehicle-edit {
  .hint {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    justify-content: center;

    svg {
      width: 1.5rem;
      fill: $blue;
    }
  }

  .checkbox-container {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
  }
}

#vehicle-details {
  .vehicle-observers {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    justify-content: flex-end;

    .observer-list-container {
      display: flex;
      flex-direction: column;
    }

    .share {
      cursor: pointer;

      img {
        width: 20px;
      }
    }
  }

  .sharing-container {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
    border-radius: $border-radius;

    .sharing {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      &:not(&:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, .07);
      }

      p {
        margin: 0;

        &:first-child {
          font-weight: 600;
        }
      }
    }
  }

  dd {
    text-align: right;
  }

  .title {
    font-weight: 700;
    width: 100%;
  }

  .sharing-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .no-puesc-integration {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }

  .info-container {
    display: flex;
    align-items: center;
    column-gap: .5rem;

    &.critical-error {
      color: $red;
    }

    svg {
      width: 1.5rem;
      color: $red;
    }
  }

  .puesc-integration {
    & > div:first-child {
      display: flex;
      align-items: center;
    }

    dt.sending-data {
      display: flex;
      align-items: center;
      column-gap: .5rem;

      span {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 1.5rem;
          color: $red;
        }
      }
    }

    table {
      width: 100%;
      max-height: 550px !important;
      border: 1px solid $navy;
      border-radius: $border-radius;

      td {
        border: none;
      }
    }
  }

  .awaiting-puesc-integration {
    p.yellow {
      color: $yellow;
    }
  }

  .exchanges-list {
    list-style-type: none;
    padding: 0;

    li:not(:last-child) {
      margin-bottom: 1rem;
    }

    .logo-container {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      img {
        width: 4rem;
      }
    }
  }
}

#select-order {
  min-height: 24rem;
  max-height: 32rem;
  min-width: 28rem;
  max-width: 38rem;

  #extend_packages_offer {
    div:last-child {
      max-height: 10rem;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      display: grid;
      grid-template-columns: 3fr 1fr 1fr 1fr;
      align-items: center;
      column-gap: 1rem;

      p:not(p:first-child), .field {
        justify-self: center;
      }

      &:first-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  button.save {
    margin-right: 0;
  }
}

