@import '../../globals';

#chat-template-creator {
  height: calc(100% - 40px);
  min-width: 700px;
  overflow-y: auto;
  .main-lists-header {
    border-bottom: 2px solid $border-color;
  }
  .chat-body {
    width: 50%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 1rem 0;
    p.title {
      width: calc(100% - 30px);
      padding-left: 30px;
      flex-flow: column;
      font-weight: 600;
      color: $navy;
      font-size: 1.1rem;
    }
    .chat-body-content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      height: 100%;
      .template-creator-container {
        box-sizing: border-box;
        width: 60%;
        height: calc(100% - 50px);
        display: flex;
        flex-flow: column;

        .template-list-container {
          overflow-y: auto;
        }
        .template-list-container {
          height: calc(100% - 200px);
          display: flex;
          flex: 1;
          flex-direction: column;

          .template-list-header {
            display: flex;
            flex: 1;
            flex-direction: row;
            background-color: #D3D6DB;

            .header {
              flex: 1;
            }
          }

          .template-list {
            display: flex;
            flex: 1;
            flex-direction: column;
            //overflow-y: scroll;
          }
          .note {
            text-align: center;
            opacity: .7;
            margin: 2em 0;
          }
        }

        .template-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px 10px 10px 30px;

          &.list-header {
            height: 40px;
            border-top: 2px solid rgba(37, 53, 77, 0.2);
            border-bottom: 2px solid rgba(37, 53, 77, 0.2);
            background-color: rgba(211, 214, 219, .1);
          }

          &.new {
            background-color: #333333;
          }

          .template-title {
            flex: 1;
          }
          .icon-container {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
              width: 100%;
              height: 100%;
              stroke-width: 1.25;
            }
            &:hover {
              svg {
                transform: scale(1.1);
              }
            }
          }


        }

        .new-template-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          padding-left: 30px;
          height: 40px;

          .new-template-title-input {
            flex: 1;
            border-radius: $border-radius;
            background-color: $input-bg-color;
            height: 30px;
            margin-right: 20px;
            padding-left: 10px;
            padding-right: 10px;
            color: $navy;
            font-size: 16px;
            &.error{
              background-color: rgba(255, 0, 0, 0.4);
            }
          }

          .icon-container {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius;
            margin: 5px;
            box-sizing: border-box;
            box-shadow: 0 2px 2px #00000019;
            cursor: pointer;

            &.add {
              background-color: #3682ff;

              &:hover {
                background-color: #6AA8FF;
              }
              &:active {
                background-color: #256ED6;
                box-shadow: none;
              }
            }

            &.abort {
              background-color: $white;
              border: 1px solid $border-color;

              &:hover {
                background-color: #FCFDFF;
              }

              &:active {
                background-color: #EBEEF2;
                box-shadow: none;
              }
            }

            .icon {
              width: 15px;
              height: 15px;
            }
          }
        }

        .footer {
          position: unset;
          box-sizing: border-box;
          height: 50px;
          .create-new {
            display: flex;
            justify-content: space-around;
            margin-right: 10px;
            span {
              svg {
                width: 1rem;
              }
            }
          }
        }
      }

      .chat-hint {
        display: flex;
        align-items: flex-start;
        width: 50%;
        padding: 0 2rem;
        span {
          margin: 0 1rem;
          transform: translateY(50%);
          svg {
            width: 2rem;
            color: #3682ff;
          }
        }
        p {
          padding: 0;
          color: #808CB1;
        }
      }
    }
  }
}
