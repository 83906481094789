@use '../../globals';

#main-poi-list {
  display: flex;
  flex-direction: column;
  flex: 1;

  .list-and-map {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .poi-type {
    display: flex;
    align-items: center;

    img {
      margin-right: .5rem;
    }
  }

  #points-table {
    overflow-y: auto;
    overflow-x: hidden;

    table {
      th, td:not(.noData) {
        display: flex;
        align-items: center;
      }
    }
  }

}

#POI-creator {
  max-height: 50%;
  overflow-y: auto;
  overflow-x: hidden;

  .header-text {
    color: globals.$navy;
    font-size: 17px;
  }

  .header-image {
    width: 24px;
    height: 24px;
  }

  form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem 5rem;
    padding-inline: 2rem;

    .field {
      margin: 0;
    }

    .rate-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 1.5rem;
      }
    }

    .checkboxes-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 1rem;
    }

    .field {
      & > div {
        height: 38px;

        .react-autosuggest__suggestions-container--open {
          max-height: 120px;
          overflow-y: auto;
        }

        input {
          height: auto;
        }
      }
    }
  }
}

#poi-details {
  .group {
    dl {
      display: grid;
      grid-template-columns: 1fr 1fr;

      img {
        width: 1.5rem;
      }

      dd {
        display: flex;
        justify-content: flex-end;
        text-align: right;
      }
    }
  }
}
