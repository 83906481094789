@use '../../globals';

.drivers-table {
  max-height: globals.$header-with-submenu-height;

  .has-invitation {
    opacity: .8;
    color: globals.$blue;
  }
}

.driver-details {
  .account-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.account-active strong {
      color: #00D700;
    }
  }

  dd {
    text-align: right;

    ul {
      list-style-type: none;
      margin: 0;
    }
  }
}
