@use '../../globals';

.map-chat-message-item {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-wrap: inherit;
  word-break: break-all;
  box-sizing: border-box;
  padding: 10px 20px;

  &.sent {
    color: globals.$blue;
  }

  &.sent-by-me {
    align-items: flex-end;
  }

  &.sent-by-other {
    align-items: flex-start;
  }

  .message-info {
    height: 20px;
    font-size: .9rem;
    color: #344A6A;
    opacity: 0.5;
    display: flex;
    align-items: center;

    &.sent {
      text-align: right;
    }

    .message-info-icon {
      width: 20px;
      height: 20px;
    }

  }

  .message-body {
    color: #344A6A;
    font-size: 1rem;
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-top: .8rem;

    &.sent {
      color: #529BFF;
    }

    &.sent-by-me {
      justify-content: flex-end;
    }

    &.sent-by-other {
      justify-content: flex-start;
    }

    .attachment-container {
      display: flex;

      &.sent {
        justify-content: flex-end;
      }

      .image {
        width: fit-content;
        max-width: 60%;
      }

      .download-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;

        .image-download {
          width: 30px;
          height: 30px;
        }
      }

    }

  }
}
