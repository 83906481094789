@import '../../globals';

#group-details {
  .group {
    .title {
      margin-bottom: .5rem;
    }

    & > .list {
      display: flex;
      flex-direction: column;
      row-gap: .5rem;

      & > p {
        margin: 0;
      }
    }
  }
}

#add-group {
  .field {
    width: 40%;
    margin: 0;
  }

  .table-container {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    column-gap: 2rem;
    align-items: flex-start;

    table {
      width: 100%;
      max-height: 550px !important;
      border: 1px solid $navy;
      border-radius: $border-radius;

      td {
        border: none;
      }
    }
  }

  .title {
    font-weight: 700;
  }
}
