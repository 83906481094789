.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
}

.modal-container {
  width: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background-color: #fff;
  z-index: 1002;
  border-radius: 10px;
  font-size: 1rem;
  color: #1D2135;
  overflow: hidden;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .7);
  transition: transform .16s;
  margin: 0 auto;

  &.visible {
    transform: translate(-50%, -50%) scale(1);
  }

  .loading {
    color: red;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1003;
    display: flex;
    align-items: center;
    justify-self: center;
  }

  display: flex;
  flex-direction: column;

  header, footer {
    flex: 0 0 auto;
  }

  main {
    flex: 1 1 auto;
    overflow: auto;
  }

  header, main, footer {
    padding: 1.2em;
  }

  header {
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #EFEFEF;
  }

  footer {
    background-color: #EFEFEF;
    text-align: right;
  }
}
