.device-details {
  .yellow {
    color: #FDBF04;
  }

  dd {
    text-align: right;
  }
}

#add-device {
  .hint-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}

#edit-device {
  form {
    padding-block: 2rem;

    .group .item {
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: center;
    }
  }
}
