@import './globals';

@import url("https://unpkg.com/leaflet@1.5.1/dist/leaflet.css");
@import url('../node_modules/react-toastify/dist/ReactToastify.css');
@import url('../node_modules/react-confirm-alert/src/react-confirm-alert.css');

html, body, #root {
  height: 100%;
  overflow: hidden;
}


#app {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  background: #fff;

  > .top-menu-container + * {
    height: calc(100% - 60px);
  }

  .react-datepicker-wrapper {
    input {
      cursor: pointer;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
      background: $white no-repeat;
      background-image: url('graphics/arrow_down.png'), linear-gradient(to bottom, transparent 8px, hsl(0, 0%, 80%) 8px, hsl(0, 0%, 80%) calc(100% - 8px), transparent calc(100% - 8px));
      background-position: right 12px center, right 36px center;
      background-size: 12px 7px, 1px 100%;
      box-sizing: border-box;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      padding: 0 44px 0 8px;
      outline: none;
      height: 35px;

      &:disabled {
        opacity: .7;
      }
    }


    //.custom-date-input {
    //  &-container {
    //    display: flex;
    //    align-items: center;
    //    padding: .5em;
    //  }
    //
    //  &-image-container {
    //    width: 20px;
    //    display: flex;
    //    align-items: center;
    //    justify-content: center;
    //
    //    .image {
    //      width: 12px;
    //    }
    //
    //  }
    //
    //  &-value {
    //    min-width: 50px;
    //  }
    //}

    .image {
    }
  }

  .button {
    min-width: 140px;
    max-width: 200px;
    width: fit-content;
    height: 35px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    box-sizing: border-box;
    text-align: center;
    font-size: 15px;
    margin-right: 10px;
    cursor: pointer;
    padding: 0 .4em;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.cancel {
      background: $white 0 0 no-repeat padding-box;
      box-shadow: 0 2px 2px #0000001A;
      color: $navy;

      &:hover:enabled {
        background-color: #FCFDFF;
      }

      &:active:enabled {
        background-color: #EBEEF2;
        box-shadow: none;
      }
    }

    &.save {
      background: $green 0 0 no-repeat padding-box;
      box-shadow: 0 2px 2px #0000001A;
      color: #ffffff;
      border: none;

      &:hover:enabled {
        background-color: #09EA12;
      }

      &:active:enabled {
        background-color: #00B708;
        box-shadow: none;
      }
    }

    &.edit {
      background: $white 0 0 no-repeat padding-box;
      box-shadow: 0 2px 2px #0000001A;
      color: $navy;

      &:hover:enabled {
        background-color: #FCFDFF;
      }

      &:active:enabled {
        background-color: #EBEEF2;
        box-shadow: none;
      }
    }

    &.action {
      background: $yellow 0 0 no-repeat padding-box;
      box-shadow: 0 2px 2px #0000001A;
      color: #000000;

      &:hover:enabled {
        background-color: #FFD047;
      }

      &:active:enabled {
        background-color: #DFA600;
        box-shadow: none;
      }
    }

    &.basic {
      background: #3B8CFF;
      box-shadow: 0 2px 2px #0000001A;
      color: #fff;

      &:hover:enabled {
        background-color: #6AA8FF;
      }

      &:active:enabled {
        background-color: #256ED6;
        box-shadow: none;
      }
    }

    &.red {
      background-color: #FF6B67;
      box-shadow: 0 2px 2px #0000001A;
      color: #fff;

      &:hover:enabled {
        background-color: #FF8F8C;
      }

      &:active:enabled {
        background-color: #D95B58;
        box-shadow: none;
      }
    }

    svg {
      margin-left: .7em;
      width: 24px;
      vertical-align: middle;

      &:only-child {
        margin-left: 0;
      }
    }
  }

  .button:disabled {
    background: #696969 0 0 no-repeat padding-box;
    color: #ffffff;
    cursor: not-allowed;
  }
}

.text-center {
  text-align: center;
}

#react-confirm-alert {
  .react-confirm-alert-overlay {
    z-index: 1020;
  }

  .react-confirm-alert-body {
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    overflow: hidden;
    padding: 16px;
    box-shadow: 0 0 40px rgba(0, 0, 0, .2);

    h1 {
      margin: -16px -16px 24px -16px;
      padding: 16px;
      font-size: 20px;
      text-align: center;
      font-weight: 700;
      color: rgb(37, 53, 77);
      border-bottom: 2px solid rgba(37, 53, 77, 0.2);
    }

    .react-confirm-alert-button-group {
      margin: 24px -16px -16px -16px;
      border-top: 2px solid rgba(37, 53, 77, 0.2);
      background: #EFEFEF;
      padding: 16px;
      justify-content: flex-end;

      button {
        background: white;
        color: rgb(37, 53, 77);
        border: 1px solid rgba(37, 53, 77, 0.2);
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

        &.confirm {
          border-color: $green;
          background-color: $green;
          color: #fff;
          font-weight: 700;
        }
      }
    }
  }
}

.header-text {
  color: $navy;
  font-size: 17px;
}

.header-image {
  width: 24px;
  height: 24px;
}

.table-text-input-container {
  width: auto;
  margin-left: 5px;
  margin-right: 5px;

  .table-text-input {
    width: 100%;
    background: $input-bg-color 0 0 no-repeat padding-box;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding-left: 5px;
    padding-right: 5px;
    height: 25px;
  }
}

.footer {
  height: 50px;
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 auto;
}

a.button {
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.icon {
  display: inline-block;
  margin: .4em;
  width: 1em;
  height: 1em;
  padding: 0;
  color: $blue;
  position: relative;
  vertical-align: middle;

  &.icon-add {
    border-radius: 100%;
    border: 2px solid;

    &:before, &:after {
      content: '';
      display: block;
      background: currentColor;
      width: 60%;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

  }
}

.not-found {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 30vmin;
}

.connection-error {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .window {
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);
    width: 200px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
    background-color: #e74c3c;
    color: #fff;
    font-weight: 700;
  }
}

button.link {
  border: none;
  background: transparent;
  display: inline;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
}

input[type="checkbox"] {
  $size: 16px;
  appearance: none;
  display: block;
  width: $size;
  height: $size;
  background: #fff;
  border: 1px solid #a0a0a0;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  outline: none;

  &:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, .3);
  }

  &:before, &:after {
    content: '';
    position: absolute;
    bottom: calc($size / 4);
    left: calc($size / 2.55);
    height: 2px;
    width: 0;
    background-color: #666;
    transform-origin: 1px 50%;
  }

  &:checked {
    &:before {
      width: calc($size / 2);
      transform: rotate(-45deg);
    }

    &:after {
      width: calc($size / 3);
      transform: rotate(-135deg);

    }
  }

  &:disabled {
    opacity: .4;
    cursor: not-allowed !important;
  }
}

table {
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;

  position: relative;
  display: block;

  thead, tbody, tfoot {
    display: block;
    width: 100%;
  }

  thead {
    //padding-right: var(--scrollbar-width);
    position: sticky;
    top: 0;
    z-index: 1;

    td, th {
      border: solid #D3D6DB;
      border-width: 0 0 2px 0;
      background-color: #F7F7F7;
      text-align: left;

      &[data-sorted] {
        user-select: none;
        $size: .5em;
        padding-right: .5em + $size*2;
        position: relative;

        &:after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          top: 50%;
          right: .5em;
          border: solid transparent;
          border-width: $size calc($size / 2);
        }

        &[data-sorted="asc" i]:after {
          top: calc(50% - (#{$size} / 2));
          border-color: currentColor transparent transparent transparent;
        }

        &[data-sorted="desc" i]:after {
          top: calc(50% - (#{$size} * 1.5));
          border-color: transparent transparent currentColor transparent;
        }
      }

      //&:last-child {
      //  margin-right: var(--scrollbar-width);
      //}
    }
  }

  &.selectable {
    thead {
      td, th {
        &:first-child[data-sorted] {
          padding-right: .5em;
          max-width: 40px;
          min-width: 40px;
        }
      }
    }

    td, th {
      &:not(.noData):first-child {
        width: 1%;
        max-width: 40px !important;
        min-width: 40px !important;
      }
    }
  }

  td, th {
    padding: .5em;

    img {
      max-width: 1.2em;
      max-height: 1.2em;
    }
  }

  td.noData {
    text-align: center;
    width: 100%;
    display: block;
  }

  tbody {
    td {
      border-right: 1px solid rgba(0, 0, 0, .07);
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .icon {
        color: $blue;
        width: 1.5rem;
      }

      .description {
        padding-block: 1rem;
      }
    }


    &:last-child tr:last-child td {
      border-bottom: 1px solid rgba(0, 0, 0, .07);
    }

    tr {
      width: 100%;
      display: block;

      &[clickable] td {
        cursor: pointer;
      }

      &.dimmed td {
        color: rgba(0, 0, 0, .3);
      }

      &:nth-child(2n) td {
        background-color: #F7F7F7;
      }

      &.active td {
        background-color: $navy !important;
        color: #fff !important;

        .poi-type img {
          filter: invert(100%) contrast(1000%) !important;
        }
      }

      &:hover {
        td {
          background-color: #F0F0F0;
        }

        //&:nth-child(2n) td { background-color: #F0F0F0; }
        &.active td {
          background-color: #2b3e5a;
        }
      }
    }
  }

  //tbody, thead tr {
  //  display: block;
  //}
  //tbody {
  //  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: auto;
  //}

  max-height: calc(100vh - 110px);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 70vw;
  transform: scale(0) translate(-50%, -50%);
  border-radius: 1em;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  background: #fff;
  z-index: 1001;
  transition: transform .3s;
  transform-origin: 0 0;

  &.visible {
    transform: scale(1) translate(-50%, -50%);
  }

  header {
    padding: .5em 2em .5em .5em;
    text-align: center;
    font-size: 1.2em;
    color: $blue;
    font-weight: 600;
    position: relative;

    .close {
      position: absolute;
      right: .3em;
      top: 0;
      cursor: pointer;
      font-size: 1.7em;
    }
  }

  main {
    max-height: 40vh;
    overflow-y: auto;
  }

  footer {
    background-color: #EFEFEF;
    padding: .5em;
    text-align: right;
    border-radius: 0 0 1em 1em;
  }
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  opacity: .2;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #0D101A;
}

.amount-circle {
  width: 1.2rem;
  height: 1.2rem;
  background-color: $blue;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: .2rem !important;
  cursor: unset;
  border: 1px solid rgba(0, 0, 0, .2);

  p {
    margin: 0;
  }
}

.react-datepicker__navigation-icon {
  width: 0;
}

.react-autosuggest__container {
  position: relative;
  display: flex;
  flex: 1;
}

.react-autosuggest__input {
  flex: 1;
  background: $input-bg-color 0 0 no-repeat padding-box;
  border: 1px solid $border-color;
  margin: 5px 0;
  padding: 0 5px;
  height: 30px;
  border-radius: $border-radius;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 34px;
  width: fit-content;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;

  img {
    padding: 0 5px 0 0;
    width: 15px;
    height: 15px;
  }
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  letter-spacing: 0;
  color: $navy;
  opacity: 0.7;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}


.sub-menu {
  background-color: #ccd1d9;
  display: flex;

  .tab {
    text-decoration: none;
    color: inherit;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 1em;
    width: fit-content;
    cursor: pointer;
    transition: background-color .16s;

    svg {
      $size: 20px;
      width: $size;
      height: $size;
      margin-right: .5em;
    }

    &:hover {
      background-color: #d5d7db;
    }

    &.active {
      background-color: #fff;
      font-weight: 600;

      svg {
        color: $blue;

        &.icon-arrows {
          fill: $blue;
        }
      }
    }
  }
}

.leaflet-control-zoom {
  display: flex;
  flex-direction: row;

  a.leaflet-control-zoom-in {
    border-bottom: none;
    border-right: 1px solid #ccc;
  }
}

.clustering-checkbox-container {
  height: 30px;
  width: 8rem;
  position: absolute;
  right: 5rem;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: .5rem;
  margin: 10px 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #fff;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  font-size: 16px;
}
