@import '../../globals';

#cart {
  .main-lists-header {
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);

    .header-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.5rem;

      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
    }

    .search-and-controls {
      button {
        margin-left: 1rem;
      }
    }
  }

  .no-offers {
    font-size: 1.3rem;
    text-align: center;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 6fr 4fr;
    height: calc(100% - 50px);

    & > div {
      padding: 1rem;
    }

    .contracts {
      grid-column: 1;
      grid-row: 1 / 3;
      border-right: 1px solid rgba(0, 0, 0, 0.2);

      table {
        max-height: 60%;
        border: 1px solid $navy;
        border-radius: $border-radius;

        th, td {
          display: flex;
          align-items: center;

          p {
            margin: 0;
          }

          .foreign-contract {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: .5rem;
            width: 100%;
          }

          &.delete-contract-cell {
            justify-content: center;

            .delete-contract-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              opacity: .4;

              &:hover {
                opacity: 1;
              }

              svg {
                width: 1.4rem;
                height: 1.4rem;
              }
            }
          }
        }
      }
    }

    .devices {
      grid-column: 2;

      .locators {
        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-block: 1rem;

          p.amount {
            font-size: 1.2rem;
          }

          .locator {
            display: flex;
            align-items: center;
            column-gap: 1rem;

            .image-container {
              width: 2.5rem;
              height: 2.5rem;
              background-color: #E6E8EB;
              border-radius: 50%;
              padding: .5rem;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
              }
            }

            p {
              font-weight: 600;
            }
          }
        }
      }
    }

    .form-container {
      grid-column: 2;
      grid-row: 2;
      background-color: #FAFAFB;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      .offer-status-info {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1rem;

        svg {
          width: 1.4rem;
          stroke: $green;
        }

        h3 {
          margin: 0;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          margin: 0;
        }

        .field {
          grid-template-columns: 1fr;

          input {
            grid-column: 1;
            width: 40%;
          }
        }

        .checkbox-container {
          grid-template-columns: auto 1fr;

          p {
            grid-column: 2;
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 1rem;

          svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
