@use '../../globals';

.dispositors-table {
  .account-disabled {
    opacity: .4;
  }

  .account-invitation {
    opacity: .8;
    color: globals.$blue;
  }
}

.dispositor-details {
  .account-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.account-active strong {
      color: #00D700;
    }
  }

  dd {
    text-align: right;
  }
}
