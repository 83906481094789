@use '../../globals';

.field {
  margin: 1em 0;
  display: grid !important;
  grid-template-columns: 4fr 6fr;
  grid-gap: 0 1em;
  align-items: center;

  label, .input-label {
    grid-column: 1;
    grid-row: 1;
    margin: 0;
  }

  input {
    grid-column: 2;
    grid-row: 1;
  }

  .hint {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    align-items: center;
    column-gap: .5rem;

    svg {
      width: 1.2rem;
      color: globals.$blue;
    }

    p {
      color: globals.$navy;
      opacity: .6;
      font-size: .9rem;
      margin: 0;
    }
  }

  &.required {
    label, .input-label {
      font-weight: 700;

      &:after {
        content: ' *';
      }
    }
  }

  &.checkbox-container {
    display: flex;
    align-items: center;

    img {
      width: 1.5rem;
      cursor: pointer;
    }

    &.error {
      img {
        border: 1px solid #e74c3c;
        border-radius: 10px;
        box-shadow: 0 0 4px #e74c3c;
      }
    }

    &.disabled {
      img {
        opacity: .5;
        cursor: not-allowed;
      }
    }

    &.without-label {
      grid-template-columns: auto !important;
    }
  }
}
