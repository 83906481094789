@import '../../globals';

.device-preview {
  position: absolute;
  left: 240px;
  max-height: calc(100% - 60px);
  background-color: #ffffff;
  width: 400px;
  display: flex;
  flex-direction: column;
  row-gap: .4rem;
  padding: .6rem 1rem;
  z-index: 999;
  border-radius: 0 0 10px 0;
  top: 60px;

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -10px;
    left: 10px;
    background: url("../../graphics/outside-corner-top-right.png") center no-repeat;
    transform: rotate(180deg);
  }

  &.invisible {
    display: none;
  }

  .device-preview-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;

    .vehicle-accesses {
      display: flex;
      align-items: center;
      column-gap: .5rem;

      .accesses-amount {
        width: 1.2rem;
        height: 1.2rem;
        background-color: $blue;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: .2rem;
        cursor: unset;
        border: 1px solid rgba(0, 0, 0, .2);

        p {
          margin: 0;
        }
      }
    }

    &.can-data {
      padding: 0;

      .device-preview-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: .3rem;

        .device-preview-can-data-item {
          width: 100%;
        }
      }
    }

    &-header, &-description {
      display: block;
    }

    &-header {
      font-size: 12px;
      color: $navy;
      opacity: 0.7;
      margin-bottom: .6em;
    }

    &-description {
      color: $navy;
      font-size: 16px;
    }

    .actions-container {
      position: absolute;
      top: 0;
      right: -.45em;
      display: flex;
      flex-direction: column;
      z-index: 3;

      span {
        cursor: pointer;
        padding: 3px 5px;
        display: flex;
        align-items: flex-start;
      }

      img {
        width: 20px;
      }

      & ~ .device-preview-item-header, & ~ .device-preview-item-description {
        padding-right: 28px;
      }
    }

    .field.checkbox-container {
      margin: 0;

      img {
        width: 1.2rem;
      }
    }

    .puesc-integration-container {
      display: flex;
      align-items: center;
      column-gap: .5rem;

      .puesc-integration-item {
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 2px 10px;
        color: #fff;
        font-weight: 600;
      }
    }

    .transport-exchanges-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: .5rem;

      & > div {
        display: flex;
        align-items: center;

        img {
          width: 100%;
        }
      }
    }
  }

  .device-preview-buttons {
    display: flex;
  }

  .device-preview-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;

    svg {
      margin-right: .3rem;
      width: 1.2rem;
      height: 1.2rem;
    }

    .value {
      color: $navy;
      font-size: 12px;

      &.alarm {
        color: red;
        font-weight: 700;
      }
    }

    .device-preview-can-data-item {
      display: grid;
      grid-template-columns: 1fr 1fr 3fr;
      align-items: center;
      column-gap: .3rem;
      padding-block: .7rem;

      svg {
        margin-right: 0;
      }
    }

    .device-preview-temperature-item {
      text-align: center;
      font-size: 12px;
      width: 54px;
      height: 16px;
      margin: 5px;
      background-color: red;
      border-radius: $border-radius;
      color: #ffffff;
    }
  }

  .overlay-loader {
    border-radius: 0 0 10px 0;
  }
}
