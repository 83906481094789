@import '../../globals';

#drag-and-drop-route-creator {
  flex: 1;
  max-height: $header-with-submenu-height;
  overflow-y: auto;

  .row-content {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    flex-direction: row;
    height: fit-content;
    padding: 5px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 2px;

    &.new-point-row {
      background-color: $white;
      cursor: pointer;
    }

    .icon-container {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        height: 20px;
        width: 20px;

        &:hover {
          scale: 1.1;
        }
      }
    }

    .row-data-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      justify-content: center;

      .row-data-item {
        flex: 1;
        display: flex;
        flex-direction: row;
        padding: 2px 0;

        .data-description {
          display: flex;
          width: 100px;
          //justify-content: fle;
          align-items: center;
          font-size: 14px;
          color: $navy;
          //opacity: 0.7;
        }

        .data-value {
          flex: 1;
          font-size: 14px;
          color: $navy;
        }
      }
    }

    .new-point-row-description {
      flex: 1;
      font-size: 16px;
      color: $navy;
    }
  }

  .new-point-creator {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #ffffff 0 0 no-repeat padding-box;
    box-shadow: 0 5px 20px #0000000D;
    border-top: 2px solid $border-color;
    border-bottom: 2px solid $border-color;
    height: fit-content;
    padding: 3px 0;

    .new-point-creator-header {
      flex: 1;
      text-align: center;
      line-height: 40px;
      color: $navy;
      font-size: 16px;
      font-weight: bold;
    }

    .input-data-container {
      display: flex;
      flex-direction: column;
      row-gap: .5rem;

      .input-row {
        display: grid;
        grid-template-columns: auto 1fr;
        padding: 0 10px;
        font-size: 14px;

        .input-description {
          display: flex;
          align-items: center;
          width: 100px;
        }

        .new-point-input {
          flex: 1;
          background: $input-bg-color 0 0 no-repeat padding-box;
          border: 1px solid $border-color;
          margin: 5px 0;
          padding: 0 5px;
          height: 30px;
          border-radius: $border-radius;

          &.error {
            background: #ffcfcf 0 0 no-repeat padding-box;
          }
        }

        .coords-container {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: center;
          column-gap: .5rem;

          & > input {
            margin: 0;
            height: 36px !important;
          }

          .poi-select {
            align-self: stretch;

            & > div {
              background-color: $input-bg-color;
            }
          }
        }
      }
    }

    .buttons-container {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 5px 0;
    }
  }

  .new-item-component {
    flex: 1;
    display: flex;
    background: #FBFBFC 0 0 no-repeat padding-box;
    box-shadow: 0 5px 20px #0000000D;
    border-top: 2px solid $border-color;
    border-bottom: 2px solid $border-color;
    height: 80px;
    padding: 6px;
    margin: 0 0 3px;

    .new-item-content {
      text-align: left;
      color: $navy;
      font-size: 14px;
    }
  }
}
