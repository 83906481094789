#mapView {
  display: flex;
  flex-direction: column;
  flex: 1;

  .map-view-content {
    display: flex;
    flex-direction: row;
    flex: 1;

    .map-container {
      flex: 1;
      height: 100%;
      width: 100%;

      #map {
        height: 100%;
        width: 100%;
      }

      .kitt {
        pointer-events: none;
      }

      .marker-moving, .marker-parking {
        position: relative;
        filter: drop-shadow(0 1px 1px rgba(0, 0, 0, .7));
      }
    }
  }
}

