@import '../../globals';

#historical-route-controls {
  position: absolute;
  width: calc(100% - 240px);
  left: 240px;
  height: 190px;
  bottom: 0;
  z-index: 9999;
  background-color: white;


  .react-tabs__tab-list {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;

    .react-tabs__tab {
      width: fit-content;
      display: flex;
      background: #F2F3F4 0 0 no-repeat padding-box;
      border-radius: 0 10px 0 0;
      border: none;
      color: $navy;

      &.selected {
        background: #ffffff 0 0 no-repeat padding-box;
        color: #529BFF
      }

      .tab-close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 100%;

        .tab-close-button-image {
          width: 15px;
          height: 15px;
        }
      }
    }

  }

  .tab-style {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:not(.react-tabs__tab-panel--selected) {
      pointer-events: none;
    }

    .historical-route-tab-content {
      box-sizing: border-box;
      padding: 10px;
      height: 100%;
      display: grid;
      grid-template-rows: 1fr 50px;

      .tab-row {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100%;
        width: 100%;

        .info-description {
          margin: auto;
          font-weight: bold;
        }

        .close-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 100%;

          .icon {
            height: 20px;
            width: 20px;
          }
        }

        .range-picker {
          display: grid;
          align-items: center;
          grid-template-columns: repeat(4, min-content);
          grid-gap: .5em;

          .react-datepicker-wrapper {
            width: 170px;
          }
        }

        .route-specs-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: .3em 2em;

          .item {
            white-space: nowrap;
            display: flex;
            justify-content: space-between;

            .label {
              //width: fit-content;
              color: $navy;

            }

            .value {
              font-weight: 600;
              margin-left: 1em;
            }
          }

        }

        .historical-route-button {
          margin-left: auto;
          margin-right: 5px;
          width: 145px;
          height: 40px;
          background: $white 0 0 no-repeat padding-box;
          box-shadow: 0 2px 2px #00000019;
          border: 1px solid $border-color;
          border-radius: $border-radius;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 16px;

          .icon {
            width: 18px;
            height: 18px;
          }

          &.active {
            background: #3682FC 0 0 no-repeat padding-box;
            color: white
          }

          &.report {
            background: $yellow 0 0 no-repeat padding-box;
            box-shadow: 0 2px 2px #0000001A;
            border-radius: $border-radius;
            color: #000000;
          }
        }

        &:nth-child(2) {
          display: grid;
          grid-template-columns: repeat(2, min-content) 1fr;
          grid-gap: 2em;
          width: 100%;

          .historical-route-button {
            white-space: nowrap;
          }
        }
      }
    }

    .slider-component {
      flex: 1;
      height: 100%;
      width: 100%;
      padding: 0 80px;
      display: flex;
      align-items: center;

      .rc-slider {
        height: 0;
        padding: 0;

        .rc-slider-step {
          height: 0;
        }

        .rc-slider-rail {
          height: 0;
          background-color: transparent;
        }

        .rc-slider-track {
          height: 0;
        }

        .rc-slider-handle {
          //display: flex;
          //justify-content: center;
          //align-items: center;
          box-shadow: none;
          border: 0;
          background: transparent top center / contain no-repeat;

          .tip {
            position: absolute;
            color: #24344E;
            width: fit-content;
            white-space: nowrap;
            font-size: .7em;
            background-color: #fff;
          }

          &.rc-slider-handle-2 {
            width: 36px;
            height: 51px;
            border-radius: 50%;
            color: white;
            margin-top: -41px;
            background-image: url("../../graphics/iko_routehist_slider_play.png");

            .tip {
              bottom: 110%;
              left: 50%;
              transform: translateX(-50%);
            }
          }

          &.rc-slider-handle-1,
          &.rc-slider-handle-3 {
            width: 36px;
            height: 55px;
            margin-top: -10px;
            border-radius: 0;

            .tip {
              top: 100%;
            }
          }

          &.rc-slider-handle-1 {
            margin-left: -14px;
            background-image: url("../../graphics/iko_routehist_start_light.png");

            .tip {
              left: -120%;
            }
          }

          &.rc-slider-handle-3 {
            margin-left: 14px;
            background-image: url("../../graphics/iko_routehist_end_light.png");

            .tip {
              right: -120%;
            }
          }
        }
      }

      .route-background {
        flex: 1;
        position: absolute;
        width: calc(100% - 180px);
        height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        > * {
          height: 100%;
        }

        .stop {
          position: absolute;
          background-color: #3B8CFF;

          &:hover {
            background: darken(#3B8CFF, 5%);
          }
        }

        .ride {
          position: absolute;
          background-color: #0FD317;

          &:hover {
            background: darken(#0FD317, 5%);
          }
        }

        .noData {
          position: absolute;
          background-color: #ffffff;

          &:hover {
            background: #ff0000;
          }
        }
      }
    }
  }
}


