@import '../../globals';

.marker-moving, .marker-parking {
  .label {
    display: block;
    position: absolute;
    bottom: 55px;
    left: 0;
    color: black;
    white-space: nowrap;
    width: min-content;
    transform: translateX(-50%);
    font-size: 1.4em;
    line-height: 1;
    border-radius: $border-radius;
    background-color: white;
    padding: .5rem;

    p.vehicle-name {
      font-weight: 500;
      margin: 0;
      text-align: center;
    }

    .parameters-container {
      margin-top: .2rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: .5rem;

      & > div {
        display: flex;
        align-items: center;
        column-gap: .3rem;

        p {
          margin: 0;
        }

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }

        p.alarm {
          color: red;
          font-weight: 700;
        }
      }
    }
  }

  .pin-image {
    display: block;
    position: absolute;
    bottom: 0;
    left: -41px;
    width: 82px;
    height: 50px;
  }
}

.marker-parking .pin-image {
  background: url("../../graphics/pin_park_blue.png") center / contain no-repeat;
}

.marker-moving {
  .label {
    bottom: 25px;
  }

  .pin-image {
    width: 48px;
    height: 48px;
    left: -24px;
    top: -24px;
    background: url("../../graphics/pins/pin_blue.png") center / contain no-repeat;
  }
}
