@use '../../globals';

.account-pane {
  z-index: 2000;

  .buttons {
    text-align: right;
  }

  .info {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 1em;

    > img {
      margin-top: .7em;
      align-self: start;
    }

    > div {
      color: globals.$navy;
      opacity: .6;

      a {
        font-weight: 700;
        color: inherit;
      }

      p {
        margin-bottom: 2em;
      }
    }

    .changelog-btn {
      display: flex;
      align-items: center;
      column-gap: .5rem;
    }
  }

  .language-selector-container {
    display: flex;
    justify-content: center;
  }
}
