@use '../../globals';

@keyframes spinCogCW {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.top-menu-container {
  display: flex;
  flex-direction: row;
  height: 60px;
  background: #344a6a;
  background: linear-gradient(to left, #436BA5, #344a6a 200px);


  position: relative;

  .menu {
    display: flex;

    a {
      color: rgba(255, 255, 255, 0.5);
      text-decoration: none;
      white-space: nowrap;
      transition: color .16s;
      &:hover {
        color: #fff;
        background-color: #2c4160;
      }
      &.active {
        color: #ffffff;
        font-weight: 700;
      }
    }

    .item {
      position: relative;
      font-size: .9em;
      a {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        min-width: 70px;


        position: relative;
        .badge {
          display: inline-flex;

          border-radius: 50%;
          width: 12px;
          height: 12px;
          padding: 6px;
          background: globals.$red;
          color: #ffffff;
          text-align: center;
          font-size: 11px;
          font-weight: 600;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
        }
      }
      > a {
        //&:hover {
        //  background: radial-gradient(at center top, rgba(255, 255, 255, 0.12), transparent 20%) center / 300% 100%;
        //}
        &.active {
          background: #2c4160;
          background: linear-gradient(to bottom, globals.$blue 4px, #2c4160 4px);
        }
      }

      .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        transform-origin: 0 0;
        transform: scaleY(0);
        transition: transform .16s;

        z-index: 9999;
        background: linear-gradient(to left, #436BA5, #344a6a 200px);
        //background-color: #2c4160;
        box-shadow: 2px 2px 5px #436BA5;
        border-radius: 0 0 5px 5px;
        color: rgba(255, 255, 255, .5);
        overflow: hidden;
        a {
          justify-content: start;
          height: 40px;
        }
      }
      &:hover {
        .dropdown {
          transform: scaleY(1);
        }
      }
    }
  }

  .report-generator-state {
    margin-left: auto;
    width: 64px;
    position: relative;
    transition: background-color .16s;
    cursor: pointer;
    &:hover {
      background: #2c4160;
    }
    .cog1, .cog2 {
      position: absolute;
      color: rgba(255, 255, 255, 0.5);
      transition: stroke .16s;
    }
    &:hover {
      .cog1, .cog2 {
        color: rgba(255, 255, 255, 1);
      }
    }
    .cog1 {
      $size: 32px;
      top: 14px;
      right: 6px;
      width: $size;
      height: $size;
      animation: spinCogCW linear 5s infinite;
    }
    .cog2 {
      $size: 22px;
      bottom: 14px;
      left: 10px;
      width: $size;
      height: $size;
      animation: spinCogCW linear 5s infinite reverse;
    }

    opacity: 0;
    pointer-events: none;
    &.visible {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .notifications, .settings {
    $size: 32px;
    padding: calc((60px - $size) / 2);
    position: relative;
    color: rgba(255, 255, 255, 0.5);
    transition: color .16s, background-color .16s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .badge {
      display: inline-flex;
      position: absolute;
      top: 5px;
      left: 5px;
      border-radius: 50%;
      $bsize: 18px;
      line-height: 1;
      width: $bsize;
      height: $bsize;
      background: globals.$red;
      color: #ffffff;
      font-size: 11px;
      align-items: center;
      justify-content: center;
      transform: scale(0);
      transition: transform .16s;
      z-index: 2;
      &.green {
        background: globals.$green;
      }
      &:before {
        content: attr(data-count);
      }
    }
    .badge:not([data-count="0"]) {
      transform: scale(1);
      & ~ svg {
        animation: notificationsDing .64s 2s;
      }
    }
    svg {
      width: $size;
      height: $size;
      transform: rotate(0);
      transition: transform .32s;
    }
    &:hover {
      color: #fff;
      background: #2c4160;
      svg {
        transform: rotate(-5deg);
      }
    }
    &.active {
      color: rgba(255, 255, 255, 1);
      svg path {
        stroke-width: 1.25;
      }
      background: #2c4160;
      background: linear-gradient(to bottom, globals.$blue 4px, #2c4160 4px);
    }
  }

  .account {
    cursor: pointer;
    text-align: left;
    width: min-content;
    white-space: nowrap;
    color: #fff;
    transition: background-color .16s;
    display: grid;
    grid-template-columns: 1fr 32px;
    grid-template-rows: 1fr 1fr;
    grid-gap: 3px;
    grid-template-areas:
        "variant icon"
        "user icon";
    padding: 0 10px;
    text-decoration: none;
    min-width: 200px;
    .variant {
      grid-area: variant;
      font-size: .9em;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 3px;
      align-self: flex-end;
      justify-self: flex-start;
    }
    .user {
      grid-area: user;
      align-self: start;
      justify-self: flex-start;
      font-size: .9em;
    }
    svg {
      grid-area: icon;
      align-self: center;
      color: rgba(255, 255, 255, .5);
      transition: color .16s;
      width: 32px;
      height: 32px;
    }
    &:hover {
      svg {
        color: #fff;
      }
      background: #2c4160;
    }
    &.active {
      color: rgba(255, 255, 255, 1);
      svg path {
        color: rgba(255, 255, 255, 1);
        stroke-width: 1.25;
      }
      background: #2c4160;
      background: linear-gradient(to bottom, globals.$blue 4px, #2c4160 4px);
    }
  }

  //.settings {
  //  $size: 32px;
  //  padding: (60px - $size) / 2;
  //  position: relative;
  //  color: rgba(255, 255, 255, 0.5);
  //  transition: color .16s, background-color .16s;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  svg {
  //    width: $size;
  //    height: $size;
  //    transform: rotate(0);
  //    transition: transform .32s;
  //  }
  //}
  &[data-user-type="user"] {
    &, .dropdown {
      background: linear-gradient(to left, #4785c3, #337392 200px);
    }
  }

  &[data-user-type="manager"] {
    &, .dropdown {
      background: linear-gradient(to left, #202641, #0d101a 200px);
    }
  }
}
@keyframes notificationsDing {
  0%, 100% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-20deg);
  }
  60% {
    transform: rotate(20deg);
  }
  80% {
    transform: rotate(-15deg);
  }
}

