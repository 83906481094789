@use '../../globals';

.route-creator-form {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  h2 {
    color: globals.$blue;
    text-align: center;
    margin: 0;
  }

  hr {
    width: calc(100% + 2rem);
    height: 1px;
    position: relative;
    left: -1rem;
    background-color: globals.$navy;
    opacity: 0.1;
    margin-bottom: 0;
  }

  .locations > span {
    display: grid;
    grid-template-columns: 1.4rem auto;
    align-items: center;
    column-gap: 1rem;

    svg {
      width: 100%;
    }
  }

  .routes-summary {
    background-color: #F2F3F4;
    padding: 1rem;
    border: 1px solid globals.$border-color;
    border-radius: .6rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .circle {
      border: 2px solid #00000033;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
    }
  }

  .device-preview-buttons {
    align-self: flex-end;

    button.save {
      margin-right: 0 !important;
    }
  }
}
