@import '../../globals';

.map-chat-template-list {
  flex: 1;
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0 0;

  .map-chat-template-list-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 40px;

    .text {
      flex: 1;
      font-weight: 600;
      font-size: 14px;
      color: #24344E;
      padding-left: 10px;
    }

    .icon-container {
      height: 100%;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .templates-container{
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 10px;
    .template{
      display: flex;
      align-items: center;
      border: 2px solid #E5E6EE;
      border-radius: $border-radius;
      padding: 5px;
      box-sizing: border-box;
      width: fit-content;
      margin-top: 5px;
      margin-bottom: 5px;
      color:#529BFF;
      font-size: 14px;
      cursor: pointer;
      &.new{
        width: 100%;
        background-color: #529BFF;
        color:#ffffff;
        cursor: cell;
      }
    }
    .note {
      text-align: center;
      opacity: .7;
      margin: 2em 0;
    }
  }
}
