.loader,
.loader:before,
.loader:after {
  background: #3B8CFF;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #3B8CFF;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.inline-loader {
  display: inline-flex;
  vertical-align: middle;
  height : 1em;
  width  : 36px; /* (6 * <margin: 2px>) + (3 * <width: 8px>) */
  justify-content: center;
  align-items: center;
  span {
    display                   : inline-block;
    height                    : 8px;
    width                     : 8px;
    margin                    : 0 2px;
    background-color          : rgba(59, 140, 255, 1);
    animation-name            : load-inline;
    animation-duration        : 500ms;
    animation-iteration-count : infinite;
    animation-direction       : alternate;
  }

  span:nth-child(1) { animation-delay: 250ms; } /* (1/2) * <animation-duration: 500ms */
  span:nth-child(2) { animation-delay: 500ms; } /* (2/2) * <animation-duration: 500ms */
  span:nth-child(3) { animation-delay: 750ms; } /* (3/2) * <animation-duration: 500ms */
}


@keyframes load-inline {
  0%   { background-color : rgba(59, 140, 255, 1); }
  100% { background-color : rgba(59, 140, 255, 0); }
}

.overlay-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
