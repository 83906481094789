@use '../../globals';

#main-form-list {
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    max-height: globals.$header-with-submenu-height;
  }
}

#form-preview {
  .form-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #24344E;

      .form-item-text {
        font-size: 16px;
        padding-bottom: 5px;
        padding-right: 15px;

        &.value {
          padding: 0;
          font-weight: bold;
        }

        &.null {
          color: #F00000
        }
      }
    }
  }
}
