@use '../../globals';

#account-settings {
  .main-lists-header {
    border-bottom: 2px solid globals.$border-color;
  }

  #account-settings-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-height: globals.$header-with-submenu-height;
    overflow-y: auto;

    .item {
      padding: 1rem 2rem;
      border-bottom: 1px solid globals.$border-color;
      border-right: 1px solid globals.$border-color;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      max-height: 26rem;

      .title {
        font-weight: 600;
        margin: 0;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.1rem;
      }

      .body {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        overflow-y: auto;

        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > p {
            margin: 0;
          }

          & > p:nth-child(2) {
            font-weight: 600;
          }

          p.invited {
            opacity: .8;
            color: globals.$blue;
          }

          .inactive {
            opacity: .3;
          }

          .icon-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 1rem;

            p {
              font-weight: 600;
              margin: 0;
            }

            span {
              display: flex;
              align-items: center;

              svg {
                width: 1.2rem;
                cursor: pointer;
                opacity: .4;

                &:hover {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .actions {
        margin-top: auto;
        align-self: flex-end;
        column-gap: 1rem;

        button {
          margin-right: 0;
        }
      }

      form {
        row-gap: 0 !important;

        .field {
          margin-block: .5rem;
        }

        .actions {
          display: flex;
          justify-content: flex-end;
          margin-top: 1rem;

          button:last-child {
            margin-right: 0;
          }
        }
      }

      img {
        width: 1.2rem;
        height: 1.2rem;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
