$header-with-submenu-height: calc(100vh - 60px - 40px - 50px);
$blue: #3E90FF;
$green: #00D309;
$navy: #25354D;
$white: #F7FAFF;
$yellow: #FBBC04;
$red: #EB0000;
$border-color: #D0D3D9;
$input-bg-color: #EAECEF;
$border-radius: 5px;
