@import '../../globals';

#main-order-list {
  table {
    max-height: $header-with-submenu-height;
  }
}

#order-preview {
  .details-body {
    display: flex;
    justify-content: space-between;

    .form-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      hr {
        width: 100%;
        border-top: 1px solid #f4f5f6;
      }

      .order-data-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #24344E;
        margin-top: 3px;
        margin-bottom: 3px;

        &.header {
          margin-top: 10px;
        }

        .order-data {
          flex: 1;
          font-size: 16px;
          padding-bottom: 5px;
          padding-right: 15px;

          &.value {
            padding: 0;
            font-weight: bold;
          }

          .button {
            height: 30px;
            align-items: center;
            display: flex;

            img {
              height: 1em;
              width: 1em;
            }
          }
        }

        .order-data-alarm {
          width: auto;
          padding: 0;
          font-weight: bold;
          text-align: right;
        }
      }
    }
  }

  .order-details {
    width: 40%;
    background-color: #F2F3F4;
    border-radius: 6px;
    padding: .5rem;

    .order-details-group {
      position: relative;
      min-height: 1em;
      color: black;
      display: grid;
      grid-template-columns: 10% 90%;
      padding: .6rem;
      margin: 0;

      .info {
        align-self: center;
        margin-left: 1.5em;
        padding-left: 1em;

        p {
          margin: 0;
        }

        .status {
          font-weight: bold;
        }
      }

      span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5em;
        height: 1.5em;
        padding: .4rem;
        margin: 0;
        line-height: 1.5em;
        border-radius: 50%;
        color: #FFF;
        background-color: #FFF;
        box-shadow: 1px 1px 5px 0 #cacbcb;

        &::after {
          content: ' ';
          position: absolute;
          display: block;
          bottom: 1px;
          top: .1px;
          left: calc(50% - 1px);
          height: 100%;
          width: 2px;
          transform: scale(1, 2);
          transform-origin: 50% -100%;
          background-color: #cacbcb;
        }

        svg {
          color: #cacbcb;
        }
      }

      &.undone {
        .info {
          opacity: .3;

          .date {
            display: none;
          }
        }

        span {
          svg {
            color: #cacbcb;
            opacity: 1;
          }
        }
      }

      &.done {
        opacity: 1;

        span {
          background-color: #00D308;

          svg {
            color: #FFF;
          }

          &::after {
            background-color: #0d101a;
            width: 2px;
            height: 100%;
          }
        }
      }

      &.last {
        span::after {
          background-color: #cacbcb;
        }
      }

      &:last-child {
        span {
          &::after {
            display: none;
          }
        }
      }

      &.in-progress {
        span {
          background-color: #4285f4;
        }
      }

      & + .order-details-group {
        margin-top: 1.5rem;
      }
    }
  }
}

#order-creator {
  width: 100%;
  max-height: $header-with-submenu-height;
  display: flex;
  flex-direction: column;

  .order-settings-columns {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    border-top: 2px solid $border-color;
    height: calc(100% - 60px);
    overflow-y: scroll;

    .order-settings-column {
      height: 100%;
      flex: 1;

      .order-settings-column-content {
        display: flex;
        height: fit-content;
        padding: 0 20px 20px 20px;
        flex-direction: column;

        .header {
          width: 100%;
          font-weight: 600;
          color: $navy;
          padding-top: 20px;
          padding-bottom: 20px;
        }

        .item {
          height: fit-content;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-size: 17px;
          padding-top: 5px;
          padding-bottom: 5px;

          .item-description {
            flex: 1;
            display: flex;
            color: $navy;
            align-items: center;
          }

          .react-datepicker-wrapper {
            flex: 1;
          }

          .item-value-container {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: $navy;
            flex-direction: row;

            .icon-edit-route-container {
              height: 35px;
              width: 35px;
              display: flex;
              justify-content: center;
              align-items: center;

              .icon-edit-route {
                width: 20px;
                height: 20px;
              }
            }

            .text-value {
              font-size: 17px;
              height: fit-content;
              padding: 2px 8px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              text-align: right;
              flex: 1;
              color: $navy;
              justify-content: flex-end;
            }

            .text-input {
              font-size: 17px;
              height: 35px;
              background: $white;
              box-shadow: 0 2px 2px #00000019;
              border: 1px solid $border-color;
              border-radius: $border-radius;
              padding: 2px 8px;
              //padding-right: 5px;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              align-items: center;
              flex: 1;
              color: $navy;
            }

            input.error {
              background-color: #ffcfcf;
            }

            .input-with-icon-container {
              @extend .text-input;

              .text {
                width: 100%;
              }

              .icon {
                width: 10px;
                height: 10px;
              }
            }
          }

          &.warning {
            justify-content: center;
            text-align: center;
            font-size: 1.2em;

            .item-description {
              flex-flow: column;

              svg {
                width: 48px;
              }

              color: $yellow;
            }
          }
        }

        .checkbox-container {
          display: flex !important;
        }
      }
    }
  }

  .footer {
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0 0 20px #00000012;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

  }

  .driver-assignment-info {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    svg {
      width: 1.5rem;
    }
  }
}

#order-creator, #order-preview {
  .puesc-sent-container {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    span.puesc-sent-warning {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 1.5rem;
      }
    }
  }
}
