.modal {
  &.modal-report-preview {
    max-width: 80vw;

    header {
      background-color: #F7F7F7;
      padding: 1rem;
      box-shadow: 0 14px 37px -27px rgba(66, 68, 90, 0.68);
      border-radius: 1em 1em 0 0;
    }

    main {
      width: 100%;
      min-height: 400px;

      &.loading {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h2 {
        text-align: center;
      }

      .report {
        h3 {
          text-align: center;
        }

        h4 {
          text-align: center;
        }

        .table {
          min-width: 900px;
          width: 100%;
          display: flex;
          flex-direction: column;

          .group {
            display: flex;
            flex-direction: column;

            &.thead {
              font-weight: bold;
              background-color: #a7a4a4;
            }
          }

          .row {
            display: grid;

            &:nth-child(even) {
              background-color: #F7F7F7;
            }

            &.dark {
              background-color: #c4c1c1;
              font-weight: 600;
            }

            .cell {
              display: flex;
              align-items: center;
              min-height: 2rem;
              border: 1px solid black;
              padding: .4rem;
            }

            &.summary {
              grid-template-columns: 1fr;

              .cell {
                justify-content: center;
              }
            }
          }
        }
      }

      .no-data {
        padding: 2rem;
        text-align: center;
        font-size: 1.4rem;
      }
    }
  }
}
