@use '../../globals';

#identifiers {
  .identifier-table {
    max-height: globals.$header-with-submenu-height;
  }

  input.error {
    background-color: #ffcfcf;
  }


  .button-container {
    display: flex;
    justify-content: flex-end;
  }

  .assignment-history {
    td {
      padding: .2em;
    }

    tr {
      width: 100%;
      display: grid;
      grid-template-columns: 3fr 2fr;

      &.future-assignments {
        grid-template-columns: 3fr 2fr 1fr;

        td {
          span {
            cursor: pointer;
            opacity: .4;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              opacity: 1;
            }

            svg {
              width: 1.5rem;
            }

          }
        }
      }
    }

    .duration {
      white-space: nowrap;
    }

    .driver {
      padding-left: 1em;
    }
  }

  .activity-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.active strong {
      color: #00D700;
    }
  }
}
