.vehicle-sharing {
  main {
    table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        padding: .7em 1em;

        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(4) {
          width: 35%;
        }

        &:nth-child(5) {
          width: 5%;
        }

        &.actions {
          white-space: nowrap;

          img {
            cursor: pointer;
          }
        }

        &.dates {
          text-align: center;
        }
      }

      thead {
        th, td {
          font-weight: 400;
        }
      }

      tbody {
        tr {
          &:hover td {
            background-color: rgba(211, 214, 219, 0.05);
          }

          &:nth-child(2n-1) {
            td {
              background-color: rgba(211, 214, 219, 0.1);
            }

            &:hover td {
              background-color: rgba(211, 214, 219, 0.15);
            }
          }
        }
      }

      .no-rows {
        text-align: center;
      }
    }

  }

  .date-selector {
    white-space: nowrap;
  }


  footer {
    form {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .persons {
        text-align: left;
        flex: 10 1 auto;
      }

      .access-schema {
        padding-left: 15px;
        text-align: left;
        flex: 7 1 auto;
      }

      .dates {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.edit-vehicle-sharing-modal {
  overflow: visible !important;

  .react-confirm-alert-body {
    width: 30rem;

    .dates {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
    }
  }
}
