@use '../../globals';

#vehicle-settings {
  .vehicle-settings-columns {
    height: globals.$header-with-submenu-height;
    display: flex;
    flex-direction: row;
    border-top: 2px solid globals.$border-color;
    overflow-y: auto;

    .vehicle-settings-column {
      flex: 1;

      .vehicle-settings-column-content {
        height: fit-content;
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-right: 1px solid globals.$border-color;
        border-bottom: 1px solid globals.$border-color;

        .header {
          width: 100%;
          font-size: 1.1rem;
          font-weight: 600;
          color: globals.$navy;
          margin-bottom: 20px;

          &.center {
            margin-top: 10px;
            text-align: center;
          }
        }

        .footer {
          margin-top: 20px;

          & > button.save {
            margin-right: 0;
          }
        }
      }
    }
  }
}
