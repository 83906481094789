@use '../../globals';

.demo-popup {
  width: 40rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  h3 {
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    svg {
      width: 4rem;
      fill: globals.$blue;
    }
  }

  & > p {
    font-size: 1.2rem;
    margin: 0;

    a {
      text-decoration: none;
      color: globals.$blue;

      &:hover {
        color: rgba(62, 144, 255, 0.8);
      }
    }
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
