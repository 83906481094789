@import '../../globals';

#main-client-list {
  display: flex;
  flex-direction: column;
  flex: 1;

  #client-table {
    th, td:not(.noData) {
      display: flex;
      align-items: center;
    }
  }
}

#client-preview {
  dl {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 1em;
    width: 100%;

    dt {
      white-space: nowrap;
    }

    dd {
      text-align: right;
    }
  }

  button.edit {
    background-color: $yellow;
  }

  .group {

    > .title {
      font-weight: 700;
      width: 100%;

      > .icon {
        cursor: pointer;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    padding: 2em 0;

    &:after {
      content: '';
      display: block;
      position: relative;
      left: -2em;
      bottom: -2em;
      width: calc(100% + 4em);
      border-bottom: 1px solid rgba(37, 53, 77, 0.11);
    }

    &:last-child:after {
      display: none;
    }

  }

  .contact-data-form {
    padding: 2em 0;
    display: none;

    &.visible {
      display: block;
    }

    .field {
      margin: 1em 0;
      display: grid;
      grid-template-columns: 4fr 6fr;
      grid-gap: 1em;
      align-items: center;

      select, input {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        border: 1px solid $border-color;
        background-color: $input-bg-color;
        border-radius: $border-radius;

        margin: 0;
        padding: .5em;
        font-weight: 600;
        font-size: inherit;

        text-align: right;

        &:required {
          border-color: #00a8ff;
          box-shadow: 0 0 3px #00a8ff;
        }
      }

      select {
        direction: rtl;

        option {
          direction: ltr;
        }
      }

      &.required {
        label:after {
          content: '*';
        }
      }
    }

  }

  .person {
    padding: 2em 0;
    line-height: 1.6;

    &:after {
      content: '';
      display: block;
      position: relative;
      left: -2em;
      bottom: -2em;
      width: calc(100% + 4em);
      border-bottom: 1px solid rgba(37, 53, 77, 0.11);
    }

    &:last-child:after {
      display: none;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1em;
    }

    .name {
      margin-right: 1em;
      font-size: 1.1em;
      font-weight: 700;

      > * {
        display: inline;

        & + *:before {
          content: ' ';
        }
      }
    }

    .account {
      margin-left: auto;

      .button {
        margin-right: 0;
        height: 30px;
      }

      .note {
        display: inline-block;
        margin-right: 1em;
      }

      .account-active {
        color: $green;
      }
    }

    .fields .field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: .5em 0;

      .value {
        font-weight: 600;
        text-align: right;
      }
    }

    .actions {
      margin-top: 1em;
      margin-left: -.5em;
      margin-right: -.5em;

      .edit, .delete {
        cursor: pointer;
        width: 18px;
        margin: .5em;
      }

    }
  }
}

#client-editor {
  .body {
    color: $navy;

    .group {
      .title {
        font-size: 1.2em;
        margin-bottom: 1em;
        font-weight: 600;
      }

      .field {
        margin: 1em 0;
        display: grid;
        grid-template-columns: 4fr 6fr;
        grid-gap: 1em;
        align-items: center;

        &.required {
          label {
            font-weight: 600;

            &:after {
              content: ' *';
            }
          }
        }

        &.error {
          select, input, textarea {
            border-color: #e74c3c;
            box-shadow: 0 0 4px #e74c3c;
          }
        }

        select, input {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;

          border: 1px solid $border-color;
          background-color: $input-bg-color;
          border-radius: $border-radius;

          margin: 0;
          padding: .5em;
          font-weight: 600;
          font-size: inherit;

          text-align: right;
        }

        select {
          direction: rtl;

          option {
            direction: ltr;
          }
        }
      }

      &.correspondence-address {
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > div {
            display: flex;
            align-items: center;

            label {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
