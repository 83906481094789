@use '../../globals';

#licenses-table {
  & > table {
    max-height: globals.$header-with-submenu-height;

    td, th {
      display: flex;
      align-items: center;
    }
  }

  .cell-with-icon {
    display: flex;
    column-gap: 1rem;

    p {
      margin: 0;
    }

    span {
      display: flex;
      align-items: center;
      column-gap: .2rem;

      svg {
        width: 1.2rem;

        path {
          stroke: #000;
        }
      }
    }
  }

  .license-vehicle {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      margin-left: 1rem;
    }

    img:hover {
      transform: scale(1.1);
    }
  }
}

#license-details {
  .group {
    &.license-details {
      dl {
        dd {
          justify-self: flex-end;

          &.expired {
            color: red;
          }
        }
      }

      .license-expired-warning {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: .5rem;
        font-size: 1.2rem;

        svg {
          width: 2rem;
          stroke: red;
        }
      }
    }

    .vehicle-accesses {
      display: flex;
      align-items: center;
      column-gap: .5rem;

      img {
        width: 1.25rem;
      }

      & > span:first-child {
        cursor: pointer;
      }
    }

    &.accesses {
      .title {
        margin-bottom: 1rem;
      }
    }

    &.hint {
      .hint-icon {
        display: flex;
        align-items: center;
      }
    }
  }
}

.modal {
  &.license-modal {
    width: 500px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1002;

    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 1.3rem;
    }
  }
}
