@use '../../globals';

.table-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .button {
    min-width: 2rem !important;
    margin-block: 1rem;

    &.active {
      color: #fff;
      background: globals.$blue;
    }

    &.inactive {
      color: #2c3e50;
      background: #f9f9f9;
    }
  }
}
