@import '../../globals';

.filters {
  display: flex;
  list-style-type: none;
  margin-inline: 2rem;

  li {
    background-color: $input-bg-color;
    box-shadow: 0 2px 2px #0000001A;
    cursor: pointer;
    padding: 0.3rem 1.2rem;

    &.arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: .8rem;
        path {
          stroke-width: 2px !important;
          stroke: black;
        }
      }

      &:hover {
        svg path {
          stroke: #fff;
        }
      }
    }

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
      &:hover {
        background-color: $input-bg-color;
        svg path {
          stroke: black;
        }
      }
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      transition: all .1s ease-in-out;
      background-color: $blue;
      color: #fff;
    }

    &.active {
      background-color: $blue;
      color: #fff;
    }
  }
}
