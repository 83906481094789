.map-chat-user-item {
  width: 100%;
  height: fit-content;
  background-color: #F2F2FA;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 2px solid rgba(37, 53, 77, .1);
  min-height: 90px;
  justify-content: space-around;
  cursor: pointer;

  &.selected {
    background-color: #fff;
  }

  .user-data-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding-left: 5px;
    .online-status-icon-container {
      width: 20px;
      height: 100%;

      .online-status-icon {
        width: 15px;
        height: 15px;
      }
    }

    .user-data {
      height: fit-content;
      font: Bold 17px/22px Source Sans Pro;

      &.unread {
        color: #4C93FF;
      }
    }
  }

  .type-info {
    width: 100%;
    padding-left: 25px;
    padding-right: 20px;
  }

  .other-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 20px;
    justify-content: space-between;
    .description {
      font-size: 12px;
      font-weight: bold;
    }
    .value {
      margin-left: 5px;
      font-size: 12px;
    }
  }
}
