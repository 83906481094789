@import '../../globals';

#access-schemas {
  width: 100%;
  height: fit-content;

  .access-schema-editor {
    .access-schema-name {
      font-size: 17px;
      height: 35px;
      background: $white;
      box-shadow: 0 2px 2px #00000019;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      padding: 2px 8px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      color: $navy;

      &.error {
        background: #ffcfcf;
      }
    }

    .schema-option {
      cursor: pointer;
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 10px;
      padding-left: 5px;

      .check-box {
        width: 24px;
        height: 24px;
        padding-right: 15px;
      }
    }
  }
}
