.language-selector {
  vertical-align: middle;
  display: inline-grid;
  grid-template-columns: repeat(3, 30px);
  grid-gap: 1em;
  align-items: center;
  justify-content: center;
  padding: 0 2em;

  a * {
    pointer-events: none;
  }

  a:hover {
    img {
      scale: 1.1;
    }
  }

  img {
    width: 30px;
    height: 20px;
    object-fit: fill;
    object-position: center;
    vertical-align: middle;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  }
}
