@use '../../globals';

#map-chat {
  position: absolute;
  width: fit-content;
  height: fit-content;
  right: 4em;
  bottom: 25px;
  z-index: 1001;

  &.expanded {
    right: 10px;
    bottom: 25px;
  }

  .chat-container {
    width: 700px;
    height: 560px;
    position: relative;

  }

  .folded-chat-icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
    height: fit-content;
    box-shadow: 0 3px 6px #00000029;

    .folded-chat-icon-background {
      display: flex;
      background-color: globals.$navy;
      width: 36px;
      height: 36px;
      border-radius: 10px;
      align-items: center;
      justify-content: center;
      color: #ffffff;

      &.unread-messages-counter {
        border-radius: 10px 0 0 10px;
        background: #EB1218 0 0 no-repeat padding-box;
      }

      &.unread-messages {
        border-radius: 0 10px 10px 0;
        background: #EB1218 0 0 no-repeat padding-box;
      }

      .folded-chat-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
