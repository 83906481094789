@import '../../globals';

.main-lists-header {
  height: 50px;
  flex: 0 0 auto;
  position: relative;
  padding: 0 .5em;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .selected-data-action {
    display: flex;
    align-items: center;

    button:disabled {
      opacity: .2;
      cursor: not-allowed !important;
    }

    .indicator {
      display: block;
      width: 20px;
      height: 20px;
      position: relative;
      margin: 0 .7em;

      &:before, &:after {
        content: '';
        top: 10px;
        left: 0;
        background: currentColor;
        display: block;
        position: absolute;
      }

      &:before {
        width: 2px;
        height: 50%;
      }

      &:after {
        width: 100%;
        height: 2px;
      }
    }
  }

  .header-text {
    color: $blue;
    font-size: 24px;
    font-weight: bold;
    width: auto;
    flex: 1 1 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .archive-orders {
    position: absolute;
    margin-left: auto;
    left: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      margin-left: 10px;
    }
  }

  .search-and-controls {
    //position: absolute;
    margin-left: auto;
    right: 0;
    //height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .dropdown {
      font-size: 1rem;
      font-weight: initial;
      display: inline-block;
      width: max-content;
      min-width: 200px;
      text-align: left;
      color: initial;
      position: relative;
      z-index: 100;
    }

    .input-container {
      width: 180px;
      height: 35px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      margin-right: 5px;
      align-items: center;
      background-color: $input-bg-color;
      border: 1px solid $border-color;
      border-radius: $border-radius;

      .input-image {
        width: 20px;
        height: 20px;
        left: 3px;
        margin-right: 5px;
        margin-left: 5px;
      }

      .search {
        flex: 1;
        height: 35px;
        width: 100%;
        //padding-left: 26px;
        //padding-right: 3px;
        box-sizing: border-box;
        background-color: transparent;
        border: none;

      }

    }

    label.filter {
      padding: 1em;
      display: inline-flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color .16s;
      border-radius: $border-radius;

      &:hover {
        background-color: rgba(0, 0, 0, .03);
      }
    }
  }

}
