@import '../../globals';

#devices-list {
  background-color: $navy;

  .search-n-sort-container {
    padding: 10px 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;

    * {
      box-sizing: border-box;
    }

    input {
      background-color: rgba(255, 255, 255, .2);
      border: none;
      border-radius: $border-radius;
      padding: .7em 1em;
      outline: none;
      flex: 1 1 auto;
      min-width: 50px;
      color: #fff;
      font-size: inherit;

      &::placeholder {
        color: rgba(255, 255, 255, .5);
      }
    }

    .sorter {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1em;
      flex: 0 0 auto;
      cursor: pointer;
      $w: 16px;
      $h: 3px;

      .icon {
        display: block;
        width: $w;
        position: relative;

        &:before, &:after {
          content: '';
          height: $h;
          position: absolute;
          right: 0;
          display: block;
        }

        &, &:before, &:after {
          height: $h;
          background-color: #fff;
        }

        &:before {
          top: -2 * $h;
          width: $w + calc($w / 4);
        }

        &:after {
          top: 2 * $h;
          width: $w - calc($w / 4);
        }
      }

      &.desc .icon {
        &:before {
          top: 2 * $h;
        }

        &:after {
          top: -2 * $h;
        }
      }
    }
  }

  width: 240px;
  //overflow: scroll;
  ::-webkit-scrollbar {
    width: 0;
  }

  .divider {
    left: 240px;
    position: absolute;
    width: 10px;
    height: calc(100% - 60px);
    background-color: #ffffff;
    z-index: 999;
    top: 60px;
  }

  .device-list {
    padding-top: 10px;
    overflow-y: scroll;
    left: 0;
    top: 60+75px;
    bottom: 0;
    position: absolute;
    width: 240px;

    ::-webkit-scrollbar {
      width: 0;
    }
  }

}
