@import '../../globals';

.input-container {
  width: 12rem;
  height: 2.2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $input-bg-color;
  border: 1px solid $border-color;
  border-radius: $border-radius;

  .input-image {
    width: 1.25rem;
    margin-inline: 5px;
  }

  .search {
    flex: 1;
    height: 2.2rem;
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
  }
}
